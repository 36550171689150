import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import { get } from 'lodash';

import { Loading } from 'components/feedback/loading';

import { fetchProfile } from './actions';
import { CompanyProfileScene } from './scene';
import { ContextHeader } from './scene/styles';

/* eslint react/no-did-update-set-state: 0 */
class PublicCompanyProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: true,
      company: null,
    };
  }

  componentDidMount() {
    this.onFetchResources();
  }

  componentDidUpdate() {
    const { isFetching } = this.state;
    if (!isFetching && get(this.state, 'company.id') !== this.companyID()) {
      this.setState({ isFetching: true });
      this.onFetchResources();
    }
  }

  onFetchResources = () => {
    const { handleFetchProfile } = this.props;

    handleFetchProfile(this.companyID()).then((company) => {
      this.setState({ isFetching: false, company });
    });
  };

  companyID = () => {
    const { match } = this.props;
    return match.params.id;
  };

  render() {
    const { handlePageChange } = this.props;
    const { company, isFetching } = this.state;
    const blankFn = () => null;

    if (isFetching) {
      return <Loading />;
    }

    return [
      <ContextHeader
        breadcrumb={['public/vendor-hub']}
        title={company.name}
        key="header"
      />,
      <CompanyProfileScene
        key="scene"
        company={company}
        edit={false}
        editDisabled={false}
        saveForm={blankFn}
        uploadLogo={blankFn}
        rateCompany={blankFn}
        isPublic
        onPageChange={handlePageChange}
      />,
    ];
  }
}

PublicCompanyProfile.propTypes = {
  match: PropTypes.object,
  handleFetchProfile: PropTypes.func,
  handlePageChange: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    handleFetchProfile: (id) => dispatch(fetchProfile(id, true)),
    handlePageChange: (url) => dispatch(push(url)),
  };
}

export const CompanyProfilePublicContainer = connect(
  null,
  mapDispatchToProps
)(PublicCompanyProfile);
