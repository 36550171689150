import { defineMessages } from 'react-intl';

export default {
  candidateStatusBadge: defineMessages({
    new: {
      id: 'app.components.misc.Badge.CandidateStatusBadge.new',
      defaultMessage: 'New',
    },
    active: {
      id: 'app.components.misc.Badge.CandidateStatusBadge.active',
      defaultMessage: 'Active',
    },
    submitted: {
      id: 'app.components.misc.Badge.CandidateStatusBadge.submitted',
      defaultMessage: 'CV submitted',
    },
    reviewed: {
      id: 'app.components.misc.Badge.CandidateStatusBadge.reviewed',
      defaultMessage: 'CV viewed',
    },
    declined: {
      id: 'app.components.misc.Badge.CandidateStatusBadge.declined',
      defaultMessage: 'Declined',
    },
  }),
  companyProfileTypeBadge: defineMessages({
    vendor: {
      id: 'app.components.misc.Badge.CompanyProfileTypeBadge.vendor',
      defaultMessage: 'Vendor',
    },
  }),
  globalWorkStatusBadge: defineMessages({
    closingIn: {
      id: 'app.components.misc.Badge.GlobalWorkStatusBadge.closingIn',
      defaultMessage: 'Closing in: ',
    },
    closed: {
      id: 'app.components.misc.Badge.GlobalWorkStatusBadge.closingIn',
      defaultMessage: 'Closed: ',
    },
    ago: {
      id: 'app.components.misc.Badge.GlobalWorkStatusBadge.ago',
      defaultMessage: '{time} ago',
    },
  }),
  reviewStatusBadge: defineMessages({
    pending: {
      id: 'app.components.misc.Badge.ReviewStatusBadge.pending',
      defaultMessage: 'pending',
    },
    accepted: {
      id: 'app.components.misc.Badge.ReviewStatusBadge.accepted',
      defaultMessage: 'verified',
    },
    rejected: {
      id: 'app.components.misc.Badge.ReviewStatusBadge.rejected',
      defaultMessage: 'declined',
    },
  }),
  workOrderStatusBadge: defineMessages({
    pending: {
      id: 'app.components.misc.Badge.workOrderStatusBadge.pending',
      defaultMessage: 'Pending',
    },
    upcoming: {
      id: 'app.components.misc.Badge.workOrderStatusBadge.upcoming',
      defaultMessage: 'Upcoming',
    },
    active: {
      id: 'app.components.misc.Badge.workOrderStatusBadge.active',
      defaultMessage: 'Active',
    },
    declined: {
      id: 'app.components.misc.Badge.workOrderStatusBadge.declined',
      defaultMessage: 'Declined',
    },
    archived: {
      id: 'app.components.misc.Badge.workOrderStatusBadge.archived',
      defaultMessage: 'Archived',
    },
  }),
  affiliationRequestStatusBadge: defineMessages({
    null: {
      id: 'app.components.misc.Badge.affiliationRequestStatusBadge.null',
      defaultMessage: 'not sent',
    },
    requested: {
      id: 'app.components.misc.Badge.affiliationRequestStatusBadge.requested',
      defaultMessage: 'Requested',
    },
    accepted: {
      id: 'app.components.misc.Badge.affiliationRequestStatusBadge.accepted',
      defaultMessage: 'Accepted',
    },
    rejected: {
      id: 'app.components.misc.Badge.affiliationRequestStatusBadge.rejected',
      defaultMessage: 'Declined',
    },
    revoked: {
      id: 'app.components.misc.Badge.affiliationRequestStatusBadge.revoked',
      defaultMessage: 'Revoked',
    },
  }),
  sharedToBadge: defineMessages({
    global: {
      id: 'app.components.misc.Badge.SharedToBadge.global',
      defaultMessage: 'Global',
    },
    internal: {
      id: 'app.components.misc.Badge.SharedToBadge.internal',
      defaultMessage: 'Internal',
    },
    vendors: {
      id: 'app.components.misc.Badge.SharedToBadge.vendors',
      defaultMessage: 'Vendors',
    },

    jobWillCloseIn: {
      id: 'app.components.misc.Badge.SharedToBadge.jobWillCloseIn',
      defaultMessage: 'This job will automatically close in {time}. ',
    },
  }),
  premiumFeatureBadge: defineMessages({
    feature: {
      id: 'app.components.misc.Badge.premiumFeatureBadge.feature',
      defaultMessage: 'Pro feature',
    },
    addon: {
      id: 'app.components.misc.Badge.premiumFeatureBadge.addon',
      defaultMessage: 'Optional add-on',
    },
  }),
  featureVersionBadge: defineMessages({
    alpha: {
      id: 'app.components.misc.Badge.premiumFeatureBadge.alpha',
      defaultMessage: 'Alpha',
    },
    beta: {
      id: 'app.components.misc.Badge.featureVersionBadge.beta',
      defaultMessage: 'Beta',
    },
  }),
  cvStatus: {
    active: {
      id: 'app.containers.Hotlist.List.active',
      defaultMessage: 'Active',
    },
    ending: {
      id: 'app.containers.Hotlist.List.ending',
      defaultMessage: 'Ending soon',
    },
    draft: {
      id: 'app.containers.Hotlist.List.draft',
      defaultMessage: 'Draft',
    },
    ended: {
      id: 'app.containers.Hotlist.List.ended',
      defaultMessage: 'Ended',
    },
    inactive: {
      id: 'app.containers.Hotlist.List.inactive',
      defaultMessage: 'Inactive',
    },
    requested: {
      id: 'app.containers.Hotlist.List.requested',
      defaultMessage: 'CV requested',
    },
    contacted: {
      id: 'app.containers.Hotlist.List.contacted',
      defaultMessage: 'Contacted',
    },
    not_contacted: {
      id: 'app.containers.Hotlist.List.not_contacted',
      defaultMessage: 'Not contacted',
    },
    revealed: {
      id: 'app.containers.Hotlist.List.revealed',
      defaultMessage: 'CV revealed',
    },
    archived: {
      id: 'app.containers.Hotlist.List.archived',
      defaultMessage: 'Archived',
    },
    own: {
      id: 'app.containers.Hotlist.List.own',
      defaultMessage: 'Own candidate',
    },
    company: {
      id: 'app.containers.Hotlist.List.company',
      defaultMessage: 'Company candidate',
    },
    declined: {
      id: 'app.containers.Hotlist.List.declined',
      defaultMessage: 'Declined',
    },
    interview: {
      id: 'app.containers.Hotlist.List.interview',
      defaultMessage: 'Interview',
    },
    created: {
      id: 'app.containers.Hotlist.List.created',
      defaultMessage: 'Candidate added to list',
    },
    no_status: {
      id: 'app.containers.Hotlist.List.no_status',
      defaultMessage: 'No status set',
    },
    to_review: {
      id: 'app.containers.Hotlist.List.to_review',
      defaultMessage: 'To review',
    },
  },
  membershipRoles: {
    owner: {
      id: 'app.containers.Settings.Members.owner',
      defaultMessage: 'Owner',
    },
    admin: {
      id: 'app.containers.Settings.Members.admin',
      defaultMessage: 'Admin',
    },
    member: {
      id: 'app.containers.Settings.Members.member',
      defaultMessage: 'Member',
    },
  },
};
