import styled, { css } from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import { Icon } from 'components/elements/icon';
import { Toggle } from 'components/form/toggle/styles/toggle';

export const Range = styled.div`
  display: flex;
  gap: 2rem;

  & > * {
    flex: 0 0 calc(50% - 1rem);
  }
`;

type InputWrapperType = {
  theme: Theme;
  isOpen: boolean;
  isValid: boolean;
};

const borderMixin = ({ isOpen, isValid, theme }: InputWrapperType) => {
  const color =
    (isOpen && theme.colors.primary) ||
    (isValid && Colors.inputOutline) ||
    Colors.red;
  return css`
    border: solid 1px ${color};
  `;
};

export const MonthYear = styled.div`
  position: relative;
`;

export const InputWrapper = styled.div<InputWrapperType>`
  display: flex;
  border-radius: 0.3rem;
  background-color: ${Colors.white};
  ${borderMixin}
`;

export const Input = styled.input<{ theme: Theme }>`
  width: 100%;
  height: 4rem;
  border: 0;
  border-radius: 0.3rem;
  padding: 0.6rem 1rem;
  color: ${Colors.text};
  ${({ theme }) => theme.typography.text.normal};

  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: ${Colors.greyLighter};
  }
  &::placeholder {
    color: ${Colors.textLighter};
  }
`;

export const IconClear = styled(Icon)`
  cursor: pointer;
  margin: auto 1rem auto 0;
`;

export const Picker = styled.div<{ pickerOverlapping?: boolean }>`
  position: absolute;
  top: calc(100% + 1rem);
  right: ${({ pickerOverlapping }) => pickerOverlapping && '-4rem'};
  z-index: 1000;
  min-width: 25rem;
  padding: 1.6rem;
  border: 1px solid ${Colors.grey};
  border-radius: 0.3rem;
  font-size: 1.4rem;
  background-color: ${Colors.white};
  color: ${Colors.text};
`;

export const Pointer = styled.div<{ pickerOverlapping?: boolean }>`
  position: absolute;
  left: ${({ pickerOverlapping }) => !pickerOverlapping && '4rem'};
  right: ${({ pickerOverlapping }) => pickerOverlapping && '8rem'};
  top: -1.5rem;
  box-sizing: content-box;
  width: 2px;
  border: 0.8rem solid transparent;
  border-bottom-color: ${Colors.white};

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: -1rem;
    left: ${({ pickerOverlapping }) => !pickerOverlapping && '-0.7rem'};
    right: ${({ pickerOverlapping }) => pickerOverlapping && '-0.7rem'};
    border: 0.8rem solid transparent;
    border-bottom-color: ${Colors.inputOutline};
  }
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const Prev = styled.div`
  position: absolute;
  left: 10%;
  cursor: pointer;
  border: 0.8rem solid transparent;
  border-right-color: ${Colors.text};
`;

export const Next = styled.div`
  position: absolute;
  right: 10%;
  cursor: pointer;
  border: 0.8rem solid transparent;
  border-left-color: ${Colors.text};
`;

const disabledYearMixin = css`
  cursor: not-allowed;

  &,
  &:hover {
    color: ${Colors.text};
  }
`;

export const Year = styled.div<{ isDisabled: boolean }>`
  font-size: 1.8rem;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    color: ${Colors.tealDark};
  }

  ${({ isDisabled }) => isDisabled && disabledYearMixin}
`;

export const Months = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 1px;
  background-color: ${Colors.grey};
  border: 1px solid ${Colors.grey};
`;

const activeMonthMixin = css`
  &,
  &:hover {
    color: ${Colors.white};
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

const disabledMonthMixin = css`
  cursor: not-allowed;

  &,
  &:hover {
    background-color: ${Colors.grey};
  }
`;

export const Month = styled.div<{
  theme: Theme;
  isActive: boolean;
  isDisabled: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.8rem;
  cursor: pointer;
  background-color: ${Colors.white};

  &:hover {
    background-color: ${Colors.grey};
  }

  ${({ isActive }) => isActive && activeMonthMixin}
  ${({ isDisabled }) => isDisabled && disabledMonthMixin}
`;

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;

  & > span {
    top: 0;
    height: 1.8rem;
    line-height: 1.8rem;
  }

  ${Toggle} {
    margin-bottom: 0 !important;
  }
`;
