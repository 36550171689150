import { push } from 'redux-first-history';
import { LongPoll, Socket } from 'phoenix';
import { get, some } from 'lodash';
import LogRocket from 'logrocket';
import qs from 'qs';

import { httpGet, httpPatch, httpPost } from 'utils';
import { UpscopeAPI } from 'components/scripts/upscope';
import { SegmentAPI } from 'components/scripts/segment';
import { DelightedAPI } from 'components/scripts/delighted';
import { FirebaseAPI } from 'components/scripts/firebase';
import { CookieScriptAPI } from 'components/scripts/cookieScript';
import { TrackJsAPI } from 'components/scripts/trackjs';

import * as UiActions from 'containers/App/actions/ui';
import { openFlashMessage } from 'components/utils/flashMessages';
import { FlashDefinition as AccountFlashDefinition } from 'containers/YourAccount/utils/flashDefinition';
import {
  GET_SESSION_REQUEST,
  GET_SESSION_SUCCESS,
  GET_SESSION_ERROR,
  CREATE_LOGIN_TOKEN_REQUEST,
  CREATE_LOGIN_TOKEN_SUCCESS,
  CREATE_LOGIN_TOKEN_ERROR,
} from 'containers/Login/constants';
import {
  ACCOUNT_UPDATE_SUCCESS,
  NOTIFICATIONS_SETTINGS_UPDATE_ERROR,
} from 'containers/YourAccount/Account/constants';
import { SUBSCRIPTION_CHANGE_SUCCESS } from 'containers/SubscriptionPlan/constants';
import {
  setExpirationTimer,
  handleSessionExpired,
} from 'containers/App/utils/expirationTimer';
import {
  isPublicPath,
  isPublicPortalPath,
  isCustomDomainPage,
} from 'containers/App/utils/helpers';

import {
  isAlternativePortalDomain,
  isProductionServer,
} from 'utils/checkEnvironment';
import { isProduction } from 'utils/node-env-vars';
import { forceLongpoll, socketURL } from 'utils/api-host';
import { setUserContextForErrorReporting } from 'utils/errorLogging';
import { isAdministrator } from 'utils/authorization/utils/auth';
import {
  askPushPermissions,
  getPushToken,
  onPushMessage,
  registerServiceWorker,
  watchRefreshPushToken,
} from 'components/utils/notification';

import { Constants } from '../constants';

// TODO: remove after debuggin for https://github.com/hellogustav/gustav-enroll/issues/9431 is finished
window.currentSessionRequestsCounter = 0;
const onCurrentSessionRequest = function onCurrentSessionRequest() {
  window.currentSessionRequestsCounter += 1;
  if (window.currentSessionRequestsCounter > 1) {
    LogRocket.track('multipleCurrentSessionRequests', {
      count: window.currentSessionRequestsCounter,
    });
  }
};

/* eslint no-console:0 */
export function setUpUserSocket(dispatch) {
  return new Promise((resolve) => {
    const options = {
      params: () => ({
        token: localStorage.getItem('phoenixAuthToken'),
      }),
      logger: (kind, msg, data) => {
        console.log(`[socket] ${kind}:`, msg, data);
        LogRocket.log('[socket]', { kind, msg, data });
      },
      reconnectAfterMs: (tries) => {
        console.log(`[socket] reconnect:`, tries);
        if (tries % 10 === 0) {
          TrackJsAPI('track', `[socket] reconnect`);
        }

        return [10, 50, 100, 150, 200, 250, 500, 1000, 2000][tries - 1] || 5000;
      },
      rejoinAfterMs: (tries) => {
        console.log(`[socket] channel reconnect:`, tries);
        if (tries % 10 === 0) {
          TrackJsAPI('track', `[socket] channel: reconnect`);
        }

        return [1000, 2000, 5000][tries - 1] || 10000;
      },
      longPollFallbackMs: 5000,
      timeout: 10000,
    };

    if (forceLongpoll) {
      options.transport = LongPoll;
    }

    const socket = new Socket(socketURL, options);

    socket.onError(() => {
      httpGet('/current_session')
        .catch((response) => {
          if (get(response, 'response.status') === 401) {
            handleSessionExpired(dispatch);
          }
        })
        .finally(onCurrentSessionRequest);
    });
    socket.onOpen(() => {
      dispatch({
        type: 'SOCKET_CONNECTED',
        socket,
      });

      resolve(socket);
    });

    socket.connect();

    setExpirationTimer(dispatch);
  });
}

export function setUpAndJoinUserChannel(dispatch, socket, user, company) {
  const channel = socket.channel(`users:${user.id}`);

  if (channel.state !== 'joined') {
    channel.join().receive('ok', () => {
      dispatch({
        type: 'USER_CHANNEL_JOINED',
        model: user,
        channel,
      });

      channel.on('users:end_session', () => {
        handleSessionExpired(dispatch);
      });

      setUpScripts(dispatch, user, company);
    });
  }
}

export function setUpAndJoinCompanyChannel(dispatch, socket, company) {
  const channel = socket.channel(`companies:${company.id}`);

  if (channel.state !== 'joined') {
    channel
      .join()
      .receive('ok', () => {
        dispatch({
          type: 'COMPANY_CHANNEL_JOINED',
          model: company,
          channel,
        });

        channel.on('counters:updated', (response) => {
          dispatch({
            type: Constants.FETCH_COUNTERS_SUCCESS,
            payload: { counters: response },
          });
        });
        channel.on('company:deactivated', () => {
          dispatch(push('/logout'));
        });
        channel.on('subscription_plan:changed', (response) => {
          dispatch({
            type: SUBSCRIPTION_CHANGE_SUCCESS,
            payload: response,
          });
        });
        channel.on('credits:changed', (response) => {
          dispatch({
            type: Constants.UPDATE_CREDITS,
            payload: response,
          });
        });
      })
      .receive('error', (reason) => {
        console.log('failed join', reason);
      });
  }
}

export function setUpAndJoinReleaseInfoChannel(dispatch, socket) {
  const channel = socket.channel('release:info');

  if (channel.state !== 'joined') {
    channel
      .join()
      .receive('ok', (msg) => {
        dispatch({
          type: 'RELEASE_CHANNEL_JOINED',
          payload: msg,
        });
      })
      .receive('error', (reason) => {
        console.log('failed join', reason);
      });
  }
}

export function setUpAndJoinDefaultChannels(dispatch, user, company) {
  setUpUserSocket(dispatch).then((socket) => {
    setUpAndJoinUserChannel(dispatch, socket, user, company);
    setUpAndJoinCompanyChannel(dispatch, socket, company);
    setUpAndJoinReleaseInfoChannel(dispatch, socket);
  });
}

const NO_DELIGHTED_PATHS = [];
/* eslint no-unused-expressions:0 */
export function setUpScripts(dispatch, user, company) {
  const username = `${user.first_name} ${user.last_name}`;

  UpscopeAPI('updateConnection', {
    identities: [user.email, username, company.name, company.company_type],
    uniqueId: user.id,
  });

  (isProductionServer(window) ||
    isCustomDomainPage() ||
    isAlternativePortalDomain()) &&
    !isAdministrator() &&
    !isPublicPath(window.location) &&
    SegmentAPI('identify', user.id, {
      email: user.email,
      user_hash: user.identity_hash,
    });

  // Prevent delighted to popup for certain pages:
  // * shared list / company portal
  !some(NO_DELIGHTED_PATHS, (path) => path.test(window.location.pathname)) &&
    !isPublicPortalPath(window.location) &&
    DelightedAPI('survey', {
      email: user.email,
      name: username,
      createdAt: user.signup_at,
      properties: {
        company: company.name,
        company_type: company.company_type,
      },
    });

  if (isProduction()) {
    LogRocket.getSessionURL((sessionURL) => {
      SegmentAPI('track', 'LogRocket', { sessionURL });
    });

    LogRocket.identify(user.id, {
      name: username,
      email: user.email,
    });
  }

  if (
    get(CookieScriptAPI('currentState'), 'categories', [])?.length !==
    CookieScriptAPI('categories')?.length
  ) {
    CookieScriptAPI('acceptAllAction');
    CookieScriptAPI('showBadge', false);
  }

  setUserContextForErrorReporting(user, company);

  if (window.firebase) {
    FirebaseAPI()
      .then((messaging) => registerServiceWorker(messaging))
      .then((messaging) => {
        if (
          user.allow_push_notification === null ||
          (user.allow_push_notification &&
            !localStorage.getItem(`pushToken:${user.id}`))
        ) {
          askPushPermissions()
            .then(() => getPushToken(messaging))
            .then((token) => {
              dispatch(Actions.createToken(token));
              onPushMessage(messaging);
              localStorage.setItem(`pushToken:${user.id}`, token);
            })
            .catch((e) => {
              switch (e) {
                case 'permission':
                  dispatch(
                    Actions.updateNotificationsSettings({
                      allow_push_notification: false,
                    })
                  );
                  break;
                default:
              }
            });
        } else if (user.allow_push_notification === true) {
          onPushMessage(messaging);
          watchRefreshPushToken(messaging, (token) => {
            dispatch(Actions.createToken(token));
            localStorage.setItem(`pushToken:${user.id}`, token);
          });
        }
      })
      .catch((error) => console.warn(error));
  }
}

export function fetchCounters() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: Constants.FETCH_COUNTERS_REQUEST });
      const scope = get(qs.parse(window.location.search), '?filters.scope');

      httpGet(`/current_company/counters?${qs.stringify({ scope })}`)
        .then((response) => {
          dispatch({
            type: Constants.FETCH_COUNTERS_SUCCESS,
            payload: { counters: response },
          });

          resolve(response);
        })
        .catch((error) => {
          dispatch({ type: Constants.FETCH_COUNTERS_ERROR, error });

          reject(error);
        });
    });
}

export const Actions = {
  currentSession: () => (dispatch) => {
    dispatch(UiActions.openPageLoading());
    dispatch({
      type: GET_SESSION_REQUEST,
    });
    httpGet('/current_session')
      .then((data) => {
        const { user, company, communities } = data;

        setUpAndJoinDefaultChannels(dispatch, user, {
          ...company,
          communities,
        });

        dispatch({
          type: GET_SESSION_SUCCESS,
          ...data,
        });

        if (user.needs_password && !user.needs_confirm_email) {
          dispatch(push('/onboarding'));
        }
        dispatch(UiActions.closePageLoading());
      })
      .catch((error) => {
        dispatch(UiActions.closePageLoading());
        dispatch({
          type: GET_SESSION_ERROR,
          error,
        });
        console.log(error);
        // it's important to reset phoenixAuthToken to avoid loop
        localStorage.removeItem('phoenixAuthToken');
        localStorage.removeItem('phoenixExpToken');
      })
      .finally(onCurrentSessionRequest);
  },

  createToken: (token) => () =>
    new Promise((resolve, reject) => {
      httpPost('/push_tokens', { token })
        .then(() => resolve())
        .catch((error) => reject(error));
    }),

  updateUserSettings: (settings) => (dispatch) =>
    new Promise((resolve, reject) => {
      httpPatch('/current_user', { settings })
        .then((response) => {
          dispatch({
            type: 'UPDATE_USER',
            data: settings,
          });

          resolve(response);
        })
        .catch((error) => reject(error));
    }),

  updateNotificationsSettings: (settings) => (dispatch) => {
    httpPatch('/current_user', { settings })
      .then((userData) => {
        if (!userData.is_simple) {
          openFlashMessage(
            AccountFlashDefinition.UpdateNotificationsSettingsSuccess
          );
        }

        dispatch({
          type: ACCOUNT_UPDATE_SUCCESS,
          payload: { userData },
        });
      })
      .catch((error) => {
        dispatch({ type: NOTIFICATIONS_SETTINGS_UPDATE_ERROR, error });
      });
  },

  keepUserAlive: (channel) => (dispatch) => {
    channel.push('ping', {}).receive('error', ({ reason }) => {
      if (reason === 'member_not_found') {
        dispatch(push('/logout'));
      }
    });
  },
};

export const createLoginToken = (redirectUrl) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: CREATE_LOGIN_TOKEN_REQUEST });

    httpPost('/login_tokens', { redirect_url: redirectUrl })
      .then((response) => {
        dispatch({
          type: CREATE_LOGIN_TOKEN_SUCCESS,
          payload: response,
        });
        resolve(response);
      })
      .catch((error) => {
        dispatch({ type: CREATE_LOGIN_TOKEN_ERROR });

        reject(error);
      });
  });
