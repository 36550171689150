import { useState, useEffect } from 'react';

import { momentToMonthYear } from 'components/utils/date';

import { MonthYear } from './index';
import * as styled from './styles';
import { FormLabel } from '../label/label';

export const MonthYearRange = (props: MonthYearRangeProps) => {
  const { value, label, onChange, presentSelect, ...rest } = props;
  const [startDate, setStartDate] = useState<MonthYear | null>(
    value?.start || null
  );
  const [endDate, setEndDate] = useState<MonthYear | null>(value?.end || null);
  const maxDate = endDate || (momentToMonthYear(rest.maxDate) as MonthYear);
  const minDate = startDate || (momentToMonthYear(rest.minDate) as MonthYear);

  useEffect(() => {
    onChange({
      start: startDate,
      end: endDate,
    });
  }, [startDate, endDate]);

  return (
    <styled.Range>
      <div>
        <FormLabel
          value={label?.start}
          required={false}
          includeIndicator={false}
        />
        <MonthYear
          {...rest}
          value={startDate}
          startDate={startDate}
          endDate={endDate}
          minDate={momentToMonthYear(rest.minDate) as MonthYear}
          maxDate={maxDate}
          onChange={setStartDate}
        />
      </div>
      <div>
        <FormLabel
          value={label?.end}
          required={false}
          includeIndicator={false}
        />
        <MonthYear
          {...rest}
          value={endDate}
          endDate={endDate}
          minDate={minDate}
          maxDate={momentToMonthYear(rest.maxDate) as MonthYear}
          onChange={setEndDate}
          presentSelect={presentSelect}
        />
      </div>
    </styled.Range>
  );
};
