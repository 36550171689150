import * as styled from './styles';

type ActionType = {
  content: string;
  onClick: VoidCallback;
};

type NagProps = {
  message: string;
  leftAction: ActionType;
  rightAction?: ActionType;
};

export const Nag = function Nag(props: NagProps) {
  const { leftAction, message = '', rightAction } = props;

  return (
    <styled.Container>
      {leftAction && (
        <styled.Left onClick={leftAction.onClick}>
          {leftAction.content}
        </styled.Left>
      )}
      <styled.Message>{message}</styled.Message>
      {rightAction && (
        <styled.Right onClick={rightAction.onClick}>
          {rightAction.content}
        </styled.Right>
      )}
    </styled.Container>
  );
};
