/**
 * REST_API_URL is declared as a global variable and it's declared in
 * internals/webpack/webpack.base.babel.js
 */

/* eslint no-undef: 0 */
export const apiHost =
  typeof REST_API_URL === 'undefined' ? process.env.REST_API_URL : REST_API_URL;

/**
 * the same explained above applies for these vars
 */
export const forceLongpoll =
  typeof FORCE_LONGPOLL === 'undefined'
    ? process.env.FORCE_LONGPOLL
    : FORCE_LONGPOLL;

const hostTypesRegexp = {
  prod: /^.*\.hellogustav\.com$/,
  netlify: /^deploy-preview.*\.netlify.com$/,
  remotedev: /^.*\.gustav.build$/,
  e2e: /^(test|staging)\.localhost\.com$/,
  local: /^localhost$/,
};

function resolveSocketURL() {
  if (typeof window !== 'undefined' && process.env.NODE_ENV === 'production') {
    const hostType = resolveHostType(window.location.hostname);
    switch (hostType) {
      case 'prod':
        return `wss://ws-${window.location.hostname}/socket`;
      case 'remotedev':
        return SOCKET_URL;
      case 'netlify':
        return 'wss://app.gustav.build/socket';
      case 'e2e':
        return process.env.SOCKET_URL || 'ws://localhost:4001/socket';
      case 'local':
        return '/socket';
      default:
        return (
          process.env.SOCKET_URL || `wss://${window.location.hostname}/socket`
        );
    }
  } else if (typeof SOCKET_URL !== 'undefined') {
    return SOCKET_URL;
  }
  return process.env.SOCKET_URL;
}

function resolveHostType(hostname) {
  const hostType = Object.keys(hostTypesRegexp).find((type) =>
    hostTypesRegexp[type].test(hostname)
  );

  return hostType !== undefined ? hostType : 'unknown';
}

export const socketURL = resolveSocketURL();
