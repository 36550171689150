import qs from 'qs';
import { isEmpty, isNumber, isObject, map, last, reduce } from 'lodash';

// Flatten react-table filter structure so we can pass it as query params to our API
// { id: 'foo', value: 'bar' }, { id: 'abc', value: 'xyz' }
// becomes:
// { foo: 'bar', abc: 'xyz' }
export const remapFiltered = (filters: HappyTableParams['filtered']): object =>
  reduce(
    filters,
    (f, filter) => ({
      ...f,
      ...(isObject(filter.value)
        ? filter.value
        : { [filter.id]: filter.value }),
    }),
    {}
  );

// Flatten react-table sorted structure so we can pass it as query params to our API
// { id: 'foo', desc: true }
// becomes:
// { by: 'bar', direction: 'desc' }
// TODO: Support multiple filters
// const remapSorted = (sorted) => ({ by: sorted[0].id, direction: sorted[0].desc ? 'desc' : 'asc' });
export const remapSorted = (
  sorted: HappyTableParams['sorted']
):
  | {
      by: string;
      direction: 'asc' | 'desc';
    }
  | undefined =>
  last(
    map(sorted, (item) => ({
      by: item.id,
      direction: item.desc ? 'desc' : 'asc',
    }))
  );

export const buildStringifiedQuery = (
  {
    paginated,
    filtered,
    sorted,
    ...params
  }: HappyTableParams & Record<string, unknown>,
  options?: qs.IStringifyOptions
) =>
  qs.stringify(
    {
      ...(isNumber(paginated?.page) && {
        page: paginated?.page,
      }),
      ...(isNumber(paginated?.pageSize) && {
        per_page: paginated?.pageSize,
      }),
      ...(!isEmpty(filtered) && {
        filters: remapFiltered(filtered),
      }),
      ...(!isEmpty(sorted) && {
        sort_by: remapSorted(sorted),
      }),
      ...params,
    },
    options
  );

export const buildPaginated = (paginate: Paginate) => ({
  page: paginate.page,
  pages: paginate.max_page,
  pageSize: paginate.per_page,
  totalCount: paginate.total_count,
});
