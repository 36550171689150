import { find } from 'lodash';

import * as styled from './styles/emoji';
import { EMOJI_DATA } from './constants/emoji';
import type { EmojiName } from './types';

type EmojiProps = {
  name?: EmojiName;
  code?: string | string[];
};

export const Emoji = function Emoji({ name, ...props }: EmojiProps) {
  if (props.code) {
    return <styled.Emoji code={props.code} {...props} />;
  }

  const emoji = find(EMOJI_DATA, { name });

  if (!emoji) {
    throw new Error(`No emoji found with the name: ${name}`);
  }

  const { code } = emoji;

  return <styled.Emoji code={code} {...props} />;
};

export const emojiToCode = (emoji: string): string[] =>
  [...emoji].map((char) => {
    const cp = char.codePointAt(0);
    if (!cp) return '';
    return cp.toString(16).toUpperCase().padStart(4, '0');
  });
