import {
  useState,
  createContext,
  useContext,
  ReactNode,
  useEffect,
} from 'react';
import { isFunction } from 'lodash';
import {
  jobSubmissionListsEnabled,
  listsEnabled,
} from 'components/utils/subscriptionPlans';

export const SharedListTypeContext = createContext<{
  sharedListType?: SharedListType;
  setSharedListType?: (type: SharedListType) => void;
}>({});

export const SharedListTypeProvider = ({
  pathname,
  children,
  company,
}: {
  pathname?: string;
  children?: ReactNode | undefined;
  company: Company;
}) => {
  const [sharedListType, setSharedListType] = useState<
    SharedListType | undefined
  >('submission');
  const value = { sharedListType, setSharedListType };

  useEffect(() => {
    let defaultListType;

    if (jobSubmissionListsEnabled(company) && listsEnabled(company)) {
      defaultListType = 'submission';
    } else if (listsEnabled(company)) {
      defaultListType = 'marketing';
    } else {
      defaultListType = 'submission';
    }

    const listTypeMatch = pathname?.match(
      /\/(submission|marketing|shared)-lists/
    )?.[1];

    const newType = (listTypeMatch === 'shared'
      ? defaultListType
      : listTypeMatch) as SharedListType | undefined;

    if (sharedListType !== listTypeMatch) {
      setSharedListType(newType || undefined);
    }
  }, [pathname, sharedListType]);

  return (
    <SharedListTypeContext.Provider value={value}>
      {isFunction(children) ? children(value) : children}
    </SharedListTypeContext.Provider>
  );
};

export const useSharedListTypeContext = () => useContext(SharedListTypeContext);
