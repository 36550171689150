import { get } from 'lodash';

import {
  httpGet,
  httpPost,
  httpPatch,
  httpUploadPut,
  httpDelete,
  httpPostBlob,
} from 'utils';
import { openPageLoading, closePageLoading } from 'containers/App/actions/ui';
import { openFlashMessage } from 'components/utils/flashMessages';

import {
  ASSET_FETCH_REQUEST,
  ASSET_FETCH_SUCCESS,
  ASSET_FETCH_ERROR,
  ASSET_UPLOAD_REQUEST,
  ASSET_UPLOAD_SUCCESS,
  ASSET_UPLOAD_ERROR,
  ASSET_DELETE_REQUEST,
  ASSET_DELETE_SUCCESS,
  ASSET_DELETE_ERROR,
  ASSET_RENAME_REQUEST,
  ASSET_RENAME_SUCCESS,
  ASSET_RENAME_ERROR,
  SHRINK_IMAGE_REQUEST,
  SHRINK_IMAGE_SUCCESS,
  SHRINK_IMAGE_ERROR,
} from './constants';

import { FlashDefinition } from './utils/flashDefinition';

export function fetchAsset(id) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: ASSET_FETCH_REQUEST });

      httpGet(`/assets/${id}`)
        .then((response) => {
          dispatch({
            type: ASSET_FETCH_SUCCESS,
            payload: response,
          });

          resolve(response);
        })
        .catch((error) => {
          dispatch({
            type: ASSET_FETCH_ERROR,
            error,
          });

          reject(error);
        });
    });
}

/* eslint-disable no-return-assign, no-param-reassign */
export function uploadAsset({ cuid, file, name, public: isPublic }) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: ASSET_UPLOAD_REQUEST });

      let asset;

      httpPost('/assets', {
        asset: { name: name || file.name, public: isPublic },
      })
        .then((response) => (asset = response))
        .then(() => httpUploadPut(asset.s3_upload_url, file))
        .catch((error) => {
          error.reason = 's3_upload';

          throw error;
        })
        .then(() =>
          httpPatch(`/assets/${asset.id}`, {
            asset: { status: 'available', public: isPublic },
          })
        )
        .then((response) => {
          dispatch({
            type: ASSET_UPLOAD_SUCCESS,
            payload: response,
          });

          resolve({ cuid, ...response });
        })
        .catch((error) => {
          if (get(error, 'reason') === 's3_upload') {
            dispatch({ type: ASSET_UPLOAD_ERROR });
          } else {
            dispatch({ type: ASSET_UPLOAD_ERROR, error });
          }
          openFlashMessage(FlashDefinition.uploadAssetError);

          reject(error);
        });
    });
}

export function deleteAsset(asset) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(openPageLoading());
      dispatch({ type: ASSET_DELETE_REQUEST });

      httpDelete(`/assets/${asset.id}`)
        .then((response) => {
          dispatch(closePageLoading());
          dispatch({
            type: ASSET_DELETE_SUCCESS,
            payload: response,
          });

          resolve(response);
        })
        .catch((error) => {
          dispatch(closePageLoading());
          dispatch({
            type: ASSET_DELETE_ERROR,
          });

          reject(error);
        });
    });
}

export function renameAsset(asset) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: ASSET_RENAME_REQUEST });

      httpPatch(`/assets/${asset.id}`, { asset: { filename: asset.filename } })
        .then((response) => {
          dispatch({
            type: ASSET_RENAME_SUCCESS,
            payload: response,
          });

          resolve(response);
        })
        .catch((error) => {
          dispatch({
            type: ASSET_RENAME_ERROR,
            error,
          });

          reject(error);
        });
    });
}
export function shrinkImage(file) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: SHRINK_IMAGE_REQUEST });
      httpPostBlob(
        `/assets/shrink`,
        { file },
        { 'Content-Type': 'multipart/form-data' }
      )
        .then((response) => {
          dispatch({ type: SHRINK_IMAGE_SUCCESS });
          resolve(response);
        })
        .catch((error) => {
          dispatch({ type: SHRINK_IMAGE_ERROR });
          reject(error);
        });
    });
}
