import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { isString, isEmpty, trim, debounce } from 'lodash';
import { compose } from 'redux';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Placeholder from '@tiptap/extension-placeholder';
import CharacterCount from '@tiptap/extension-character-count';
import Highlight from '@tiptap/extension-highlight';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';

import { trimEmptyParagraphs } from 'components/utils/html';
import { Colors } from 'components/utils/styles/ui';
import { isValid } from 'components/utils/form-utils';

import * as styled from './styles';

import { FloatingVariables } from './ui/floatingVariables';
import { ShowMoreLess } from './ui/showMoreLess';
import { TextTools } from './ui/textTools';
import { ButtonAskAI } from './ui/buttonAskAI';
import { ValidationErrorIndicator } from '../validationErrorIndicator';

import { PLAIN_TEXT_OPTS } from './utils';
import i18n from './utils/i18n';
import { VariableExtension } from './extensions/variableExtension';
import { NoNewLineOnEnter } from './extensions/NoNewLineOnEnter';
import { GetSelectedHTML } from './extensions/getSelectedHTML';
import { AITextInput } from '../rteAITextInput';

function isContentExpandable({ editable, editor, element, defaultHeight }) {
  if (editable || !editor || !element) return false;

  const rte = element.getElementsByClassName('ProseMirror')[0];
  if (!rte || !defaultHeight) {
    return false;
  }

  const height = rte.scrollHeight;

  const expandable = height > parseFloat(defaultHeight.replace('rem', '')) * 10;
  return expandable;
}

export const WithEditable = (Component) => {
  function EnrichedComponent({ editable, ...props }) {
    return (
      <Component
        key={`rte-${editable.toString()}-editable`}
        editable={editable}
        {...props}
      />
    );
  }

  EnrichedComponent.propTypes = {
    editable: PropTypes.bool,
  };

  EnrichedComponent.defaultProps = {
    editable: true,
  };

  return EnrichedComponent;
};

export function RichTextEditorComponent({
  value,
  withFloatingTextTools,
  promptSuggestions = [],
  withButtonAskAI,
  placeholder,
  className,
  intl,
  onChange,
  debounceOpts,
  getTextRef,
  variablesList,
  variablesI18n,
  editable,
  height: defaultHeight,
  resizable,
  dataManual,
  limit,
  withBarTextTools,
  showCharactersCounter,
  textToolsOptions,
  noNewLineOnEnter,
  extraFloatingContentHeight,
  shouldValidate,
  required,
  ...styledProps
}) {
  const [height, setHeight] = useState(defaultHeight);
  const [hasFocus, setHasFocus] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  const [askAIOpen, setAskAIOpen] = useState(false);
  const awaitingFloatingMenu = useRef(false);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      VariableExtension({ i18n: variablesI18n, editable }),
      Placeholder.configure({
        placeholder: isString(placeholder)
          ? placeholder
          : intl.formatMessage(placeholder),
      }),
      noNewLineOnEnter && NoNewLineOnEnter,
      CharacterCount.configure({
        limit,
      }),
      Highlight.configure({ color: Colors.tealLight }),
      GetSelectedHTML,
      Table,
      TableRow,
      TableHeader,
      TableCell,
    ],
    content: value,
    editable,
    onUpdate: editable
      ? debounce((rte) => {
          const textContent = trim(rte.editor.getText());
          const htmlContent = rte.editor.getHTML();
          const content =
            isEmpty(textContent) && isEmpty(trimEmptyParagraphs(htmlContent))
              ? null
              : htmlContent;
          setCurrentValue(content);
          onChange(content);
        }, debounceOpts.wait)
      : undefined,
    onFocus: () => {
      setHasFocus(true);
    },
    onBlur: () => {
      setHasFocus(false);
    },
  });

  const wrapperRef = useRef();
  const floatingMenuRef = useRef();

  const handleClick = (expanded) => {
    setHeight(expanded ? 'auto' : defaultHeight);
  };

  const handleClickAskAI = () => {
    awaitingFloatingMenu.current = true;
    editor.chain().selectAll().focus().run();
  };

  const handleOnShowFloatingMenu = () => {
    if (awaitingFloatingMenu.current) {
      setAskAIOpen(true);
      awaitingFloatingMenu.current = false;
    }
  };

  useEffect(() => {
    if (getTextRef && editor) {
      // eslint-disable-next-line no-param-reassign
      getTextRef.current = {
        getText: () => editor.getText(PLAIN_TEXT_OPTS),
        setContent: (content) => editor.commands.setContent(content),
      };
    }
  }, [getTextRef, editor]);

  useEffect(() => {
    if (editor && value !== currentValue) {
      setCurrentValue(value);
      editor.commands.setContent(value);
    }
  }, [value]);

  const displayShowMoreLess = isContentExpandable({
    editable,
    editor,
    element: wrapperRef.current,
    defaultHeight,
  });

  const isInputValid = isValid({
    inputValue: value,
    shouldValidate,
    inputType: 'rte',
    required,
  });

  const displayFloatingVariables =
    editable && !isEmpty(variablesList) && !isEmpty(variablesI18n);

  return (
    <styled.Container>
      {withBarTextTools && (
        <TextTools editor={editor} type="toolbar" items={textToolsOptions} />
      )}
      {shouldValidate && !isInputValid && (
        <ValidationErrorIndicator inputType="rte" />
      )}

      {editor && editable && askAIOpen && (
        <AITextInput
          editor={editor}
          editorRef={wrapperRef}
          floatingMenuRef={floatingMenuRef}
          setAskAIOpen={setAskAIOpen}
          promptSuggestions={promptSuggestions}
        />
      )}

      <styled.Wrapper
        {...styledProps}
        height={height}
        className={className}
        ref={wrapperRef}
        hasFocus={hasFocus}
        data-manual={`${dataManual}.input`}
        resizable={editable && resizable}
        editable={editable}
        withOverflow={!displayShowMoreLess}
        isValid={isInputValid}
      >
        {withButtonAskAI && (
          <ButtonAskAI
            onClick={handleClickAskAI}
            icon="MagicWand"
            floating={false}
            disabled={editor?.isEmpty}
            withBarTextTools={withBarTextTools}
          />
        )}

        {displayFloatingVariables && (
          <FloatingVariables
            variablesList={variablesList}
            editor={editor}
            parentElementRef={wrapperRef}
            dataManual={dataManual}
            extraContentHeight={extraFloatingContentHeight}
          />
        )}
        {withFloatingTextTools && editable && (
          <TextTools
            ref={floatingMenuRef}
            editor={editor}
            type="floating"
            items={textToolsOptions}
            setAskAIOpen={setAskAIOpen}
            withButtonAskAI={withButtonAskAI}
            onShow={handleOnShowFloatingMenu}
          />
        )}
        <EditorContent editor={editor} />
        {displayShowMoreLess && (
          <ShowMoreLess editable={editable} onClick={handleClick} />
        )}
      </styled.Wrapper>
      {showCharactersCounter && editor && (
        <styled.CharCount>
          {editor.storage.characterCount.characters()}
          {limit ? `/${limit}` : ''}
        </styled.CharCount>
      )}
    </styled.Container>
  );
}

RichTextEditorComponent.propTypes = {
  intl: PropTypes.object,
  withFloatingTextTools: PropTypes.bool,
  withBarTextTools: PropTypes.bool,
  textToolsOptions: TextTools.propTypes.items,
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ id: PropTypes.string, defaultMessage: PropTypes.string }),
  ]),
  value: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  debounceOpts: PropTypes.shape({
    wait: PropTypes.number,
  }),
  getTextRef: PropTypes.object,
  variablesList: PropTypes.array,
  variablesI18n: PropTypes.object,
  editable: PropTypes.bool,
  height: PropTypes.string,
  resizable: PropTypes.bool,
  dataManual: PropTypes.string,
  limit: PropTypes.number,
  showCharactersCounter: PropTypes.bool,
  noNewLineOnEnter: PropTypes.bool,
  extraFloatingContentHeight: PropTypes.number,
  shouldValidate: PropTypes.bool,
  isValid: PropTypes.bool,
  required: PropTypes.bool,
  withButtonAskAI: PropTypes.bool,
};

RichTextEditorComponent.defaultProps = {
  withFloatingTextTools: true,
  placeholder: i18n.editorPlaceholder,
  resizable: false,
  editable: true,
  debounceOpts: { wait: 100 },
};

export const RichTextEditor = compose(
  injectIntl,
  WithEditable
)(RichTextEditorComponent);

export { parseHtml } from './utils';
