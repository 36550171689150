import { useState } from 'react';
import ReactPageClick from 'react-page-click';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';

import { Colors } from 'components/utils/styles/ui';
import { Emoji, emojiToCode } from 'components/elements/emoji';

import * as styled from './styles/inputs';

type EmojiPickerProps = {
  className?: string;
  onChange: (icon: string[]) => void;
  value: string[];
  disabled: boolean;
};

export const EmojiPicker = ({
  className,
  onChange,
  value: selectedEmoji,
  disabled,
}: EmojiPickerProps) => {
  const [pickerOpen, setPickerOpen] = useState(false);
  const [previewEmoji, setPreviewEmoji] = useState(selectedEmoji);

  const handleEmojiSelect = (emoji: EmojiData) => {
    const code = emojiToCode(emoji.native);
    onChange?.(code);
    setPreviewEmoji(code);
    setPickerOpen(false);
  };

  const togglePicker = (e: React.MouseEvent) => {
    e.stopPropagation();
    setPickerOpen((prev) => !prev);
  };

  const closePicker = () => {
    setPickerOpen(false);
  };

  return (
    <ReactPageClick notify={closePicker}>
      <styled.PickerContainer className={className}>
        {/* @ts-expect-error fix after converting styles to ts */}
        <styled.EmojiPreviewWrapper onClick={togglePicker} disabled={disabled}>
          <styled.EmojiPreview>
            <Emoji code={previewEmoji} />
          </styled.EmojiPreview>
        </styled.EmojiPreviewWrapper>

        {!disabled && pickerOpen && (
          <styled.EmojiPickerWrapper>
            <Picker
              native
              autoFocus
              color={Colors.tealDark}
              emojiSize={20}
              skin={1}
              showPreview={false}
              showSkinTones={false}
              onSelect={handleEmojiSelect}
            />
          </styled.EmojiPickerWrapper>
        )}
      </styled.PickerContainer>
    </ReactPageClick>
  );
};
