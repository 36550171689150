import { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import cuid from 'cuid';
import TimeMe from 'timeme.js';

import { socketSelector } from 'containers/App/selectors';
import { IS_MOBILE } from 'utils/constants';

import { connectToChannel, leaveChannel, trackEvent } from './actions';
import { startView, endView } from './utils/helpers';

const VISIBILITYCHANGE = 'visibilitychange';
const BEFOREUNLOAD = 'beforeunload';
const MIN_VIEW_DURATION = 0.1;
let UNLOADING = false;

const AnalyticsComponent = (props) => {
  const {
    history,
    location,
    socket,
    handleConnectToChannel,
    handleLeaveChannel,
    handleTrackEvent,
  } = props;
  const eventRef = useRef(null);
  const [channel, setChannel] = useState(null);
  const [prevLocation, setLocation] = useState({ pathname: '' });

  const sendEvent = useCallback(
    (async, end) => {
      if (!eventRef.current) {
        return;
      }
      const duration = TimeMe.getTimeOnCurrentPageInSeconds();

      if (duration >= MIN_VIEW_DURATION) {
        const data = {
          ...eventRef.current,
          duration: parseInt(duration, 10),
          end: end || UNLOADING,
          is_mobile: IS_MOBILE,
        };
        handleTrackEvent(channel, data, async);
      }
    },
    [channel, eventRef.current]
  );

  const onRouteChange = (currLocation) => {
    const newEvent = startView(prevLocation, currLocation);

    if (prevLocation.pathname === currLocation.pathname) {
      return;
    }

    if (!eventRef.current && newEvent) {
      TimeMe.resetAllRecordedPageTimes();
      TimeMe.startTimer();
      eventRef.current = {
        ...newEvent,
        cuid: cuid(),
        started_at: moment.utc().format(),
      };
      setTimeout(() => sendEvent(false, false), MIN_VIEW_DURATION * 1000);
    } else if (
      eventRef.current &&
      endView(eventRef.current, prevLocation, currLocation)
    ) {
      sendEvent(false, true);

      if (newEvent) {
        TimeMe.resetAllRecordedPageTimes();
        TimeMe.startTimer();
        eventRef.current = {
          ...newEvent,
          cuid: cuid(),
          started_at: moment.utc().format(),
        };
        setTimeout(() => sendEvent(false, false), MIN_VIEW_DURATION * 1000);
      } else {
        TimeMe.resetAllRecordedPageTimes();
        eventRef.current = null;
      }
    }

    setLocation(currLocation);
  };

  useEffect(() => {
    if (socket && !channel) {
      handleConnectToChannel(socket).then(setChannel);
    } else if (!socket && channel) {
      setChannel(null);
    }
  }, [socket]);

  useEffect(
    () => () => {
      if (channel) {
        handleLeaveChannel(channel);
      }
    },
    []
  );

  useEffect(() => {
    const unlisten = history.listen((currLocation) => {
      onRouteChange(currLocation);
    });

    return unlisten;
  }, [history.location, prevLocation]);

  useEffect(() => {
    onRouteChange(location);
  }, []);

  useEffect(() => {
    const visibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        sendEvent(true, false);
      }
    };
    const beforeUnload = () => {
      UNLOADING = true;
    };

    document.addEventListener(VISIBILITYCHANGE, visibilityChange);
    window.addEventListener(BEFOREUNLOAD, beforeUnload);

    return () => {
      document.removeEventListener(VISIBILITYCHANGE, visibilityChange);
      window.removeEventListener(BEFOREUNLOAD, beforeUnload);
    };
  }, [eventRef.current]);

  return null;
};

AnalyticsComponent.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  socket: PropTypes.object,
  handleConnectToChannel: PropTypes.func,
  handleLeaveChannel: PropTypes.func,
  handleTrackEvent: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  socket: socketSelector,
});

function mapDispatchToProps(dispatch) {
  return {
    handleConnectToChannel: (socket) => dispatch(connectToChannel(socket)),
    handleLeaveChannel: (channel) => dispatch(leaveChannel(channel)),
    handleTrackEvent: (channel, event, async) =>
      dispatch(trackEvent(channel, event, async)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export const Analytics = compose(withConnect, withRouter)(AnalyticsComponent);
