import { monthsAbbr, monthsFull } from '../constants';

export const patterns: DatePattern[] = [
  // MM/DD/YYYY or MM-DD-YYYY or M/D/YYYY
  {
    regex: /^(\d{1,2})[/-](\d{1,2})[/-](\d{4})$/,
    groups: { monthInput: 1, yearInput: 3 },
  },
  // MM/DD/YY or MM-DD-YY or M/D/YY
  {
    regex: /^(\d{1,2})[/-](\d{1,2})[/-](\d{2})$/,
    groups: { monthInput: 1, yearInput: 3 },
  },
  // MM/YYYY or MM-YYYY or M/YYYY
  {
    regex: /^(\d{1,2})[/-](\d{4})$/,
    groups: { monthInput: 1, yearInput: 2 },
  },
  // MM/YY or MM-YY or M/YY
  {
    regex: /^(\d{1,2})[/-](\d{2})$/,
    groups: { monthInput: 1, yearInput: 2 },
  },
  // Month YY
  {
    regex: new RegExp(
      `^(${monthsFull.join('|')}|${monthsAbbr.join('|')})[\\s,]+(\\d{2})$`,
      'i'
    ),
    groups: { monthInput: 1, yearInput: 2 },
    monthName: true,
  },
  // Month YYYY
  {
    regex: new RegExp(
      `^(${monthsFull.join('|')}|${monthsAbbr.join('|')})[\\s,]+(\\d{4})$`,
      'i'
    ),
    groups: { monthInput: 1, yearInput: 2 },
    monthName: true,
  },
  // YYYY Month
  {
    regex: new RegExp(
      `^(\\d{4})[\\s,]+(${monthsFull.join('|')}|${monthsAbbr.join('|')})$`,
      'i'
    ),
    groups: { yearInput: 1, monthInput: 2 },
    monthName: true,
  },
  // YYYY/MM or YYYY-MM
  {
    regex: /^(\d{4})[/-](\d{1,2})$/,
    groups: { yearInput: 1, monthInput: 2 },
  },
  // MMYYYY
  { regex: /^(\d{1,2})(\d{4})$/, groups: { monthInput: 1, yearInput: 2 } },
  // MMYY
  { regex: /^(\d{1,2})(\d{2})$/, groups: { monthInput: 1, yearInput: 2 } },
  // YYYY
  { regex: /^(\d{4})$/, groups: { yearInput: 1 } },
];
