import {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  ChangeEvent,
} from 'react';
import { useIntl } from 'react-intl';
import { ReactPageClick } from 'react-page-click';
import { range, some, isEmpty } from 'lodash';
import moment from 'moment';

import { Toggle } from 'components/form/toggle/toggle';
import { monthYearComparable } from 'components/utils/date';

import * as styled from './styles';
import { monthsAbbr, monthsFull } from './constants';
import { patterns } from './utils/patterns';

const handleMonthInput = (match: RegExpMatchArray, pattern: DatePattern) => {
  if (!pattern.groups.monthInput) return null;

  const monthValue = match[pattern.groups.monthInput];

  if (pattern.monthName) {
    const monthIndex = monthsFull.findIndex(
      (m) => m.toLowerCase() === monthValue.toLowerCase()
    );
    const abbrIndex = monthsAbbr.findIndex(
      (m) => m.toLowerCase() === monthValue.toLowerCase()
    );

    if (monthIndex >= 0) {
      return monthIndex + 1;
    }
    if (abbrIndex >= 0) {
      return abbrIndex + 1;
    }

    return null;
  }

  const monthInput = parseInt(monthValue, 10);
  const monthInputNotValid =
    Number.isNaN(monthInput) || monthInput < 1 || monthInput > 12;

  if (monthInputNotValid && monthInput) {
    return 'month invalid';
  }
  if (!monthInputNotValid && monthInput) {
    return monthInput;
  }

  return null;
};

const handleYearInput = (match: RegExpMatchArray, pattern: DatePattern) => {
  if (!pattern.groups.yearInput) return null;

  const yearValue = match[pattern.groups.yearInput];
  const yearInput = parseInt(yearValue, 10);
  const presentYear = new Date().getFullYear();
  const yearValid = yearInput >= 1960 && yearInput <= presentYear;

  if (yearValue.length === 2) {
    const twoDigitPresentYear = presentYear % 100;
    const century = Math.floor(presentYear / 100) * 100;
    const is21stCentury = yearInput <= twoDigitPresentYear + 20;

    return is21stCentury ? yearInput + century : yearInput + century - 100;
  }

  return yearValid ? yearInput : null;
};

export const MonthYear = (props: MonthYearProps) => {
  const {
    value,
    endDate,
    startDate,
    required,
    placeholder,
    shouldValidate,
    onChange,
    minDate,
    maxDate,
    presentSelect,
  } = props;
  const intl = useIntl();
  const today = useMemo<moment.Moment>(() => moment().startOf('year'), []);
  const presentYear = new Date().getFullYear();
  const presentMonth = new Date().getMonth();
  const [year, setYear] = useState<number | null>(value?.year || null);
  const [month, setMonth] = useState<number | null>(value?.month || null);
  const [present, setPresent] = useState<boolean>(value?.present || false);
  const [currentYear, setCurrentYear] = useState<number>(year || presentYear);
  const [inputValue, setInputValue] = useState<string>('');
  const [changeInputValue, setChangeInputValue] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const mount = useRef(false);
  const isValid = (!shouldValidate ||
    (required && (year || present))) as boolean;
  const [pickerOverlapping, setPickerOverlapping] = useState(false);
  const monthYearInputRef = useRef<HTMLDivElement>(null);

  const isDisabled = useCallback(
    (newYear: number, newMonth: number | null): boolean => {
      const newDateStr = monthYearComparable({
        year: newYear,
        month: newMonth,
      });
      const minDateStr = monthYearComparable(minDate);
      const maxDateStr = monthYearComparable(maxDate);

      return newDateStr > maxDateStr || newDateStr < minDateStr;
    },
    [minDate, maxDate]
  );

  const changeCurrentYear = useCallback(
    (direction: number) => {
      setCurrentYear(currentYear + direction);
    },
    [currentYear]
  );

  const selectYear = useCallback(() => {
    setYear(currentYear);
    setMonth(null);
    setPresent(false);
    setOpen(false);
  }, [currentYear]);

  const selectMonth = useCallback(
    (newMonth: number) => {
      setYear(currentYear);
      setMonth(newMonth);
      setPresent(false);
      setOpen(false);
    },
    [currentYear]
  );

  const onOpen = useCallback(() => {
    setOpen(true);
    setCurrentYear(year || currentYear);
  }, [currentYear, year]);

  const togglePresent = useCallback(() => {
    setPresent(!present);

    setYear(present ? presentYear : null);
    setMonth(present ? presentMonth + 1 : null);
  }, [currentYear, present]);

  const onClear = () => {
    setInputValue('');
    setYear(null);
    setMonth(null);
    setPresent(false);
    setOpen(false);
  };

  const parseMonthYearInput = (input: string) => {
    const trimmedInput = input.trim();

    const yearMatch = trimmedInput.match(patterns[10].regex);
    const yearDate = yearMatch && handleYearInput(yearMatch, patterns[10]);

    if (yearDate && !isDisabled(yearDate, null)) {
      return { monthInput: null, yearInput: yearDate };
    }

    for (let i = 0; i < patterns.length; i += 1) {
      const pattern = patterns[i];
      const match = trimmedInput.match(pattern.regex);
      if (match) {
        const monthInput = handleMonthInput(match, pattern);
        const yearInput = handleYearInput(match, pattern);

        if (
          monthInput === 'month invalid' ||
          !yearInput ||
          isDisabled(yearInput, monthInput)
        ) {
          return null;
        }

        return { monthInput, yearInput };
      }
    }

    return null;
  };

  const handleMonthYearInput = (input?: string) => {
    const monthAndYear = parseMonthYearInput(input || inputValue);

    if (!monthAndYear) return;

    const { monthInput, yearInput } = monthAndYear;
    setPresent(false);
    setCurrentYear(yearInput);
    setYear(yearInput);
    setMonth(monthInput);
  };

  const formatInputValue = () =>
    year &&
    month &&
    setInputValue(
      today
        .year(year as number)
        .month((month as number) - 1)
        .format('MMMM YYYY')
    );

  useEffect(() => {
    if (!open) return;
    if (!startDate) {
      if (endDate?.present || !endDate?.year || present) {
        setCurrentYear(presentYear);
      } else if (endDate?.year) {
        setCurrentYear(endDate.year);
      }
    } else {
      setCurrentYear(startDate.year!);
    }
  }, [endDate?.year, open, startDate, year]);

  useEffect(() => {
    if (presentSelect && present) {
      setInputValue(intl.formatMessage(presentSelect.value));
    } else if (changeInputValue) {
      formatInputValue();
    } else if (year && !month) {
      setInputValue(year.toString());
    } else {
      setInputValue(inputValue);
    }

    if (mount.current) {
      if (year === null && month === null && present === false) {
        onChange(null);
      } else if (some([year, month, present], (item) => item !== undefined)) {
        onChange({ year, month, present });
      }
    }
    mount.current = true;
  }, [year, month, present, presentSelect]);

  useEffect(() => {
    const measureDistance = () => {
      if (monthYearInputRef.current) {
        const select = monthYearInputRef.current.getBoundingClientRect();
        const distToLeft = select.left;

        setPickerOverlapping(window.innerWidth - distToLeft < 230);
      }
    };

    window.addEventListener('resize', measureDistance);

    measureDistance();

    return () => {
      window.removeEventListener('resize', measureDistance);
    };
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleMonthYearInput();
      formatInputValue();
      setOpen(false);
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (e.target.value.length > 3) {
      setChangeInputValue(false);
      handleMonthYearInput(e.target.value);
    }
  };

  const handleReactPageClick = () => {
    setOpen(false);
    formatInputValue();
  };

  const handlePickYear = () => {
    if (!isDisabled(currentYear, 0)) {
      setChangeInputValue(false);
      selectYear();
    }
  };

  const handlePickMonth = (monthDisabled: boolean, currentMonth: number) => {
    if (!monthDisabled) {
      setChangeInputValue(true);
      selectMonth(currentMonth);
      formatInputValue();
    }
  };

  return (
    <ReactPageClick notify={() => handleReactPageClick()}>
      <styled.MonthYear ref={monthYearInputRef}>
        <styled.InputWrapper isOpen={open} isValid={isValid}>
          <styled.Input
            type="text"
            value={inputValue}
            placeholder={intl.formatMessage(placeholder)}
            onClick={onOpen}
            onChange={(e) => handleOnChange(e)}
            onKeyDown={(e) => handleKeyDown(e)}
          />
          {!isEmpty(inputValue) && (
            <styled.IconClear
              icon="X"
              color="text"
              size="xlarge"
              onClick={onClear}
            />
          )}
        </styled.InputWrapper>
        {open && (
          <styled.Picker pickerOverlapping={pickerOverlapping}>
            <styled.Pointer pickerOverlapping={pickerOverlapping} />
            <styled.Header>
              <styled.Prev onClick={() => changeCurrentYear(-1)}></styled.Prev>
              <styled.Year
                onClick={handlePickYear}
                isDisabled={isDisabled(currentYear, 0)}
              >
                {currentYear}
              </styled.Year>
              <styled.Next onClick={() => changeCurrentYear(1)}></styled.Next>
            </styled.Header>
            <styled.Months>
              {range(1, 13).map((currentMonth: number) => {
                const monthDisabled = isDisabled(currentYear, currentMonth);

                return (
                  <styled.Month
                    key={`month-${currentMonth}`}
                    isActive={year === currentYear && month === currentMonth}
                    isDisabled={monthDisabled}
                    onClick={() => handlePickMonth(monthDisabled, currentMonth)}
                  >
                    {today.month(currentMonth - 1).format('MMM')}
                  </styled.Month>
                );
              })}
            </styled.Months>
            {presentSelect && (
              <styled.ToggleWrapper>
                <Toggle
                  size="small"
                  color="green"
                  checked={!!present}
                  options={[
                    { checked: true, label: presentSelect.label },
                    { checked: false, label: presentSelect.label },
                  ]}
                  onChange={togglePresent}
                />
              </styled.ToggleWrapper>
            )}
          </styled.Picker>
        )}
      </styled.MonthYear>
    </ReactPageClick>
  );
};
